<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <v-btn class="info font-weight-bold" @click="showDialog"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">ユーザー設定</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="item.id" />
          <ValidationObserver ref="observer">
            <h3 id="title_head">基本情報</h3>
            <div class="mt-2 mb-2">ユーザーに関する情報を設定して下さい。</div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="登録ユーザー名"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model="item.name"
                      name="name"
                      :error-messages="errors"
                      :success="valid"
                      label="登録ユーザー名"
                      hint="会社名・団体名・グループ名等、ユーザーの名称を入力してください[必須項目]"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="メールアドレス"
                    rules="email"
                  >
                    <v-text-field
                      v-model="item.email"
                      name="email"
                      :error-messages="errors"
                      :success="valid"
                      label="メールアドレス"
                      hint="メールアドレスを入力してください"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
            <h3 id="title_head">ログイン情報</h3>
            <div class="mt-2 mb-2">
              ユーザーのログインID、パスワードを設定します。
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="ログインID"
                    rules="required|min:4|max:20"
                  >
                    <v-text-field
                      v-model="item.loginid"
                      name="loginid"
                      :error-messages="errors"
                      :success="valid"
                      label="ログインID"
                      hint="ログインIDを入力してください[必須項目]"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="パスワード"
                    :rules="
                      item.id == 0 ? 'required|min:6|max:20' : 'min:6|max:20'
                    "
                  >
                    <v-text-field
                      v-model="item.password"
                      name="password"
                      :error-messages="errors"
                      :success="valid"
                      label="パスワード"
                      type="password"
                      outlined
                      hint="パスワードを入力してください[必須項目]、編集時は、変更する場合のみ入力してください"
                      persistent-hint
                      autocomplete="new-password"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="パスワード確認"
                    :rules="
                      item.id == 0
                        ? 'required|min:6|max:20|confirmed:パスワード'
                        : 'min:6|max:20|confirmed:パスワード'
                    "
                  >
                    <v-text-field
                      ref="password_confirmation"
                      v-model="item.password_confirmation"
                      name="password_confirmation"
                      :error-messages="errors"
                      :success="valid"
                      label="パスワード確認"
                      type="password"
                      outlined
                      hint="確認用です。パスワードと同じ内容を入力して下さい。"
                      persistent-hint
                      autocomplete="new-password"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>

            <h3>補足</h3>
            <div class="mt-2 mb-2">
              ユーザーに関する補足情報を設定して下さい。管理画面でのみ表示されます。
            </div>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="ノート"
                    rules=""
                  >
                    <v-text-field
                      name="note"
                      v-model="item.note"
                      :error-messages="errors"
                      :success="valid"
                      :readonly="false"
                      label="ノート"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { set, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  setup(props, ctx) {
    const usersRepository = repositoryFactory.get("users");
    const state = reactive({
      dialog: false, // ダイアログ表示用
      item: {
        id: 0,
        name: "",
        loginid: "",
        password: "",
        password_confirmation: "",
        email: "",
        note: "",
      },
    });

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const clearDialog = async () => {
      // 各種項目初期化
      state.item.id = 0;
      state.item.name = "";
      state.item.loginid = "";
      state.item.password = "";
      state.item.password_confirmation = "";
      state.item.email = "";
      state.item.note = "";
    };

    const editDialog = async (userId) => {
      await usersRepository
        .admin_get(userId)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            state.item.id = response.data.id;
            state.item.name = response.data.name;
            state.item.loginid = response.data.loginid;
            state.item.email = response.data.email;
            state.item.note = response.data.note;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:dialogComponent.vue/onSubmit usersRepository.admin_get (" +
            error +
            ")"
          );
        });
      state.dialog = true;
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();
      if (state.item.password != "") {
        if (state.item.password_confirmation == "") {
          // エラー処理
          alert("パスワード確認を入力してください。");
          ctx.refs.password_confirmation.focus();
          return;
        }
      }

      if (!isValid) {
        // エラー処理
        return;
      } else {
        if (!window.confirm("保存してよろしいですか？")) {
          return false;
        }
        // ローディング表示
        store.dispatch("loadingIcon/showIcon");

        // 保存処理
        const params = {
          id: state.item.id,
          name: state.item.name,
          loginid: state.item.loginid,
          password: state.item.password,
          email: state.item.email,
          note: state.item.note,
          is_active: true,
        };

        // 新規か更新か
        if (state.item.id == 0) {
          // 新規
          await usersRepository
            .admin_create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.item.id = response.data.id; // idをセット
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
                error +
                ")"
              );
            });
        } else {
          // 更新
          await usersRepository
            .admin_update(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:dialogComponent.vue/onSubmit usersRepository.update (" +
                error +
                ")"
              );
            });
        }

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");

        cancelDialog();

        ctx.emit("updated", params);
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      clearDialog();
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    const init = async () => {
      // 初期化
      clearDialog();
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      editDialog,
      onSubmit,
      cancelDialog,
      cancelCastDialog,
      showDialog,
    };
  },
});
</script>

<style scoped></style>
