var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"info font-weight-bold",on:{"click":_vm.showDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("新規登録")]),_c('span',{staticClass:"d-sm-none"},[_vm._v("新規")])],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"100%","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("ユーザー設定")])]),_c('v-card-text',{staticClass:"scrollable-content"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.id),expression:"item.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.item.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "id", $event.target.value)}}}),_c('ValidationObserver',{ref:"observer"},[_c('h3',{attrs:{"id":"title_head"}},[_vm._v("基本情報")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("ユーザーに関する情報を設定して下さい。")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"登録ユーザー名","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"name","error-messages":errors,"success":valid,"label":"登録ユーザー名","hint":"会社名・団体名・グループ名等、ユーザーの名称を入力してください[必須項目]","persistent-hint":"","outlined":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"メールアドレス","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"email","error-messages":errors,"success":valid,"label":"メールアドレス","hint":"メールアドレスを入力してください","persistent-hint":"","outlined":""},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})]}}])})],1)],1)],1),_c('h3',{attrs:{"id":"title_head"}},[_vm._v("ログイン情報")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" ユーザーのログインID、パスワードを設定します。 ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"ログインID","rules":"required|min:4|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"loginid","error-messages":errors,"success":valid,"label":"ログインID","hint":"ログインIDを入力してください[必須項目]","persistent-hint":"","outlined":""},model:{value:(_vm.item.loginid),callback:function ($$v) {_vm.$set(_vm.item, "loginid", $$v)},expression:"item.loginid"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"パスワード","rules":_vm.item.id == 0 ? 'required|min:6|max:20' : 'min:6|max:20'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"password","error-messages":errors,"success":valid,"label":"パスワード","type":"password","outlined":"","hint":"パスワードを入力してください[必須項目]、編集時は、変更する場合のみ入力してください","persistent-hint":"","autocomplete":"new-password"},model:{value:(_vm.item.password),callback:function ($$v) {_vm.$set(_vm.item, "password", $$v)},expression:"item.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"パスワード確認","rules":_vm.item.id == 0
                      ? 'required|min:6|max:20|confirmed:パスワード'
                      : 'min:6|max:20|confirmed:パスワード'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-text-field',{ref:"password_confirmation",attrs:{"name":"password_confirmation","error-messages":errors,"success":valid,"label":"パスワード確認","type":"password","outlined":"","hint":"確認用です。パスワードと同じ内容を入力して下さい。","persistent-hint":"","autocomplete":"new-password"},model:{value:(_vm.item.password_confirmation),callback:function ($$v) {_vm.$set(_vm.item, "password_confirmation", $$v)},expression:"item.password_confirmation"}})]}}])})],1)],1)],1),_c('h3',[_vm._v("補足")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" ユーザーに関する補足情報を設定して下さい。管理画面でのみ表示されます。 ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"ノート","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"note","error-messages":errors,"success":valid,"readonly":false,"label":"ノート","outlined":""},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":""},on:{"click-event":_vm.cancelDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("キャンセル")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmit},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }