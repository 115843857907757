<template>
  <div>
    <app-bar ref="refAppBar" @updated="updated"></app-bar>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [100, 200, 400, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon class="pa-1" @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.login="{ item }">
          <v-icon class="pa-1" @click="login(item)"> mdi-login </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <v-icon class="pa-1" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import appBar from "./appBar.vue";

export default defineComponent({
  components: {
    appBar,
  },
  setup(_, ctx) {
    const usersRepository = repositoryFactory.get("users");
    const refAppBar = ref();
    const state = reactive({
      search: "",
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "登録ユーザー名",
          value: "name",
          sortable: true,
          class: "td_name",
        },
        // {
        //   text: "ログインID",
        //   value: "loginid",
        //   sortable: true,
        //   class: "td_loginid",
        // },
        // {
        //   text: "メールアドレス",
        //   value: "email",
        //   sortable: true,
        //   class: "td_email",
        // },
        {
          text: "ノート",
          value: "note",
          sortable: true,
          class: "td_note",
        },
        {
          text: "ログイン",
          value: "login",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    const editItem = (user) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "edit",
        value: user.id,
      });
    };

    // データ削除
    const deleteItem = async (item) => {
      if (window.confirm("削除してよろしいですか？")) {
        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // 削除処理
        await usersRepository
          .admin_delete(item.id)
          .then((response) => {
            if (response.data) {
              // データ削除OK
              alert(`「${item.name}」が削除されました。`);
            }
          })
          .catch((error) => {
            throw (
              "ERROR:admin/user/index.vue/onSubmit usersRepository.admin_delete (" +
              error +
              ")"
            );
          });

        init();

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      }
    };

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      state.desserts.splice(0);
      await usersRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.desserts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/user/index.vue/onSubmit usersRepository.admin_list (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const updated = (user_data) => {
      init();
    };

    const login = async (item) => {
      if (
        window.confirm(
          `ユーザー「${item.name}」でログインしますか？\n※ポップアップブロックにより画面が開かない場合があります。その際は許可をお願いします。`
        )
      ) {
        const token = await usersRepository
          .admin_get_jwt(item.loginid)
          .then((response) => {
            if (response.data) {
              return response.data.token;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:admin/user/index.vue/login usersRepository.admin_get_jwt (" +
              error +
              ")"
            );
          });

        // ログイン処理
        window.open(`/impersonate/${item.loginid}/${token}`, "_blank");
      }
    };

    // 返却オブジェクト定義
    return {
      refAppBar,
      setting,
      ...toRefs(state),
      editItem,
      deleteItem,
      init,
      updated,
      login,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_name {
  min-width: 100px;
  width: 250px;
}
::v-deep .td_note {
  min-width: 150px;
}
</style>
